<template>
  <div class="pad-home">
    <HeaderBar
      title="搜索地址"
      :showLeft="true"
    ></HeaderBar>
    <div class="search">
      <div
        class="searchBox"
        @click.stop.prevent="search"
      >
        <input
          type="text"
          placeholder="请输入位置"
          v-model="address"
          readonly
        />
      </div>

      <div class="tabs">
        <van-tabs
          v-model="active"
          title-inactive-color="#333"
          color="#0CAA32"
          type="line"
          :border="false"
          title-active-color="#0CAA32"
        >
          <van-tab title="著名商圈">
            <div class="fame-business racconAdd-business">
              <van-collapse
                v-model="activeName"
                accordion
                :border="false"
              >
                <van-collapse-item
                  :name="index+1"
                  v-for="(value, name, index) in businessDistrict"
                  :key="index"
                >
                  <div
                    slot="title"
                    class="title"
                  >
                    <i class="title-gps"></i>
                    {{name}}
                  </div>
                  <div
                    class="fame-items"
                    v-for="(item,index) in value"
                    :key="index"
                    @click="selectHotAddress(item)"
                  >{{item.Name}}</div>
                </van-collapse-item>
                <!-- <van-collapse-item name="2">
                <div slot="title"
                     class="title">
                  <i class="title-gps"></i>
                  夜店
                </div>
                <div>提供多样店铺模板，快速搭建网上商城</div>
              </van-collapse-item>
              <van-collapse-item name="3">
                <div slot="title"
                     class="title">
                  <i class="title-gps"></i>
                  商场
                </div>
                <div>提供多样店铺模板，快速搭建网上商城</div>
              </van-collapse-item>
              <van-collapse-item name="4">
                <div slot="title"
                     class="title">
                  <i class="title-gps"></i>
                  学校
                </div>
                <div>提供多样店铺模板，快速搭建网上商城</div>
                </van-collapse-item>-->
              </van-collapse>
            </div>
          </van-tab>
          <van-tab title="我的地址">
            <div
              class="myAddress racconAdd-myAddress"
              v-if="addressList.length>0"
            >
              <div
                class="addressItem"
                v-for="(item,index) in addressList"
                :key="index"
                @click.stop.prevent="selectUserAdd(item)"
              >
                <div class="addIcon"></div>
                <div class="addressDetail">
                  <div class="addressTitle">{{item.Address}}</div>
                  <div class="addressinfo">{{item.Address}}</div>
                </div>
                <div
                  class="deleteAdd"
                  @click.stop.prevent="deleteAdd('2',index,item.ID)"
                ></div>
              </div>
            </div>
            <div
              class="noAddress"
              v-if="addressList.length===0"
            >
              <div class="noaddimg">
                <img
                  src="../../assets/noAddress@2x.png"
                  alt
                />
              </div>
              <div class="noTxt">暂无地址信息，请添加地址</div>
            </div>
            <div class="setAddress">
              <div
                class="clearAddress"
                @click.stop.prevent="deleteAdd('1','-1')"
              >清空收藏</div>
              <div
                class="addAddress"
                @click="addUserAddress"
              >添加地址</div>
            </div>
          </van-tab>
          <van-tab title="历史记录">
            <div class="addHistoryList racconAdd-history">
              <div
                class="addressHistoryItem"
                v-for="item in historyAddressList"
                :key="item.ID"
                @click.stop.prevent="selectAdd(item)"
              >
                <div class="historyLocate"></div>
                <div class="historyAddressInfo">{{item.EndAddress}}</div>
                <!-- <div class="deleteHistory"
                @click.stop.prevent="deleteHistory(item.ID)"></div>-->
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import {
  Toast,
  Search,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Popup,
  Dialog,
} from "vant";
import { delUserAddress } from "@/service/";
import { setStore, getStore, removeStore } from "../../lib/storage";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "searchaddress",
  components: {
    HeaderBar,
    [Search.name]: Search,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Popup.name]: Popup,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      address: "",
      active: 0,
      activeName: "1",
      // addressList: [],
    };
  },
  computed: {
    ...mapState({
      businessDistrict: ({ globalVal }) => globalVal.businessDistrict,
      historyAddressList: ({ globalVal }) => globalVal.searchHitory,
      addressList: ({ userVal }) => userVal.userAddress,
      transferLocation: ({ pathVal }) =>
        pathVal.transferLocation || getStore("transferAddress"),
    }),
    type() {
      return this.$router.params.type;
    },
    addressRouter() {
      return this.$router.params.address;
    },
  },
  methods: {
    ...mapMutations([
      "SET_START_PLACE",
      "SET_END_PLACE",
      "ADD_TRANSFER_LOCATION",
    ]),
    ...mapActions(["getBusinessDistrict", "getSearchHistory", "getAddress"]),
    async deleteAdd(type, index, ressid) {
      let _this = this;
      Dialog.confirm({
        title: "删除地址",
        message: "确认删除该地址吗？",
        showCancelButton: true,
        lockScroll: true,
        confirmButtonColor: "#0CAA32",
      })
        .then(async () => {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true, // 禁用背景点击
            loadingType: "spinner",
            message: "loading...",
          });
          let Umid = JSON.parse(getStore("userInfo")).ID;
          let res = await delUserAddress(Umid, type, ressid);
          Toast.clear();
          if (res.code == 1) {
            this.active = 1;
            setStore("adactive", this.active);
            // this.$router.go(0);
            window.location.reload();
            // this.$router.reload();
          }
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    addUserAddress() {
      this.active = 1;
      setStore("adactive", this.active);
      let type = this.$route.params.type;
      this.$router.push(`/search/${type}/0`);
    },
    deleteHistory(id) {
      console.log(`delete the history address${id}`);
    },
    // async autocomplete () {
    //   let res = await autocompleteAdd(this.address)
    //   console.log(res)
    // },
    // async getAddress () {
    //   let uid = JSON.parse(getStore('userInfo')).ID
    //   let res = await getUserAddress(uid)
    //   if (res.code == 1) {
    //     this.addressList = res.data
    //   }
    // },
    selectAdd(item) {
      let type = this.$route.params.type;
      let address = this.$route.params.address;
      let place = {
        description: item.EndAddress,
        latlng: eval("(" + item.EndGeo + ")"),
      };
      if (address === "1") {
        this.SET_START_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "2") {
        this.SET_END_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "3") {
        let _place = {
          city: item.EndAddress,
          geo: eval("(" + item.EndGeo + ")"),
        };
        localStorage.setItem("indexWhich", this.$route.query.indexWhich);
        this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);
        // this.$router.push(`/${type}`)
      }
      this.$router.push(`/${type}`);
    },
    selectUserAdd(item) {
      let type = this.$route.params.type;
      let address = this.$route.params.address;
      let place = {
        description: item.Address,
        latlng: eval("(" + item.Region + ")"),
      };
      // console.log(place)
      if (address === "1") {
        this.SET_START_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "2") {
        this.SET_END_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "3") {
        // this.ADD_TRANSFER_LOCATION(place)
        let _place = {
          city: item.Address,
          geo: eval("(" + item.Region + ")"),
        };
        localStorage.setItem("indexWhich", this.$route.query.indexWhich);
        this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);

        // this.$router.push(`/${type}`)
      }
      this.$router.push(`/${type}`);
    },
    selectHotAddress(item) {
      let type = this.$route.params.type,
        address = this.$route.params.address;
      let place = {
        description: item.Name,
        latlng: eval("(" + item.Geo + ")"),
      };
      if (address === "1") {
        this.SET_START_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "2") {
        this.SET_END_PLACE(place);
        // this.$router.push(`/${type}`)
      } else if (address === "3") {
        let _place = {
          city: item.Name,
          geo: eval("(" + item.Geo + ")"),
        };
        localStorage.setItem("indexWhich", this.$route.query.indexWhich);
        this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);
        // this.$router.push(`/${type}`)
      }
      this.$router.push(`/${type}`);
    },
    search() {
      let type = this.$route.params.type;
      let address = this.$route.params.address;
      // this.$router.push(`/search/${type}/${address}`);
      this.$router.push({
        path: `/search/${type}/${address}`,
        query: {
          indexWhich: this.$route.query.indexWhich,
        },
      });
    },
  },
  watch: {},
  beforeRouteLeave(to, form, next) {
    let type = this.$route.params.type;
    if (to.path == `/search/${type}/0`) {
    } else {
      removeStore("adactive");
    }
    next();
  },
  mounted() {
    // let autocom = new google.maps.places.Autocomplete(this.$refs.input);
    this.active = getStore("adactive") ? Number(getStore("adactive")) : 0;
    let umid = JSON.parse(getStore("userInfo")).ID;
    this.getSearchHistory(umid);
    this.getAddress(umid);
    this.getBusinessDistrict();
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.search {
  overflow: hidden;
  height: 100%;
}

.searchBox {
  margin: 30px 30px 40px;
  padding: 20px;
  height: 80px;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  input {
    width: 80%;
    height: 42px;
    padding-left: 60px;
    background: rgba(238, 238, 238, 1) url("../../assets/search@2x.png") 0
      center no-repeat;
    background-size: 40px 40px;
    .fontSize(30px);
    line-height: 42px;
  }
}
.tabs {
  height: 100%;
  // padding-top: 135px;
  box-sizing: border-box;
  overflow: hidden;
  .van-tab {
    .van-ellipsis {
      font-size: 30px !important;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  .fontSize(30px) !important;
  line-height: 42px;

  .title-gps {
    display: inline-block;
    .size(26px, 34px);
    margin-right: 20px;
    background: url("../../assets/position@2x.png") center center no-repeat;
    background-size: 26px 34px;
    line-height: 42px;
  }
}
.racconAdd {
  &-business {
    // height: e('calc(100vh - 140px)');
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &-myAddress {
    // height: e('calc(100vh - 200px)');
    // padding-bottom: 160px;
    -webkit-overflow-scrolling: touch;
  }

  &-history {
    // height: e('calc(100vh - 140px)');
    -webkit-overflow-scrolling: touch;
  }
}

.fame {
  &-business {
    padding: 0 30px;
    // height: e('calc(100vh - 140px)');
    overflow: auto;
  }

  &-items {
    height: 80px;
    padding: 0 46px;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 80px;
    background: rgba(248, 248, 248, 1);
  }
}

.myAddress {
  overflow: auto;
  // height: e('calc(100vh - 200px)');
  padding: 0 30px 160px;
  box-sizing: border-box;
}
.addressItem {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  width: 690px;
  height: 190px;
  margin: 30px auto;
  padding: 40px 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  box-sizing: border-box;

  .addIcon {
    .size(26px, 34px);
    margin-right: 20px;
    box-sizing: border-box;
    background: url("../../assets/position@2x.png") 0 0 no-repeat;
    background-size: 26px 34px;
  }

  .addressDetail {
    width: 522px;
    .addressTitle {
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .addressinfo {
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 34px;
    }
  }

  .deleteAdd {
    .size(32px, 32px);
    margin-left: 30px;
    box-sizing: border-box;
    background: url("../../assets/delete_add@2x.png") 0 0 no-repeat;
    background-size: 32px 32px;
  }
}

.noAddress {
  margin-top: 132px;
  .text-center;
  .noaddimg {
    .size(210px, 318px);
    margin: 0 auto 46px;
    img {
      width: 100%;
    }
  }
  .noTxt {
    font-size: 32px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 44px;
  }
}

.setAddress {
  // .size()
  position: fixed;
  bottom: 60px;
  left: 30px;
  right: 30px;
  .fontSize(30px);

  display: flex;
  flex: 1;
  justify-content: space-between;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  text-align: center;

  .clearAddress {
    width: 160px;
    height: 80px;
    border-radius: 4px;
    border: 2px solid rgba(12, 170, 50, 1);
    color: rgba(12, 170, 50, 1);
    line-height: 80px;
  }

  .addAddress {
    width: 500px;
    height: 80px;
    background: rgba(12, 170, 50, 1);
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
    line-height: 80px;
  }
}

.addHistoryList {
  padding: 0 30px;
  // height: e('calc(100vh - 140px)');
  overflow: auto;

  .addressHistoryItem {
    // height: 100px;
    display: flex;
    flex: 1;
    padding: 30px 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #eee;

    .historyLocate {
      .size(26px, 34px);
      margin-right: 20px;
      background: url("../../assets/position@2x.png") 0 0 no-repeat;
      background-size: 26px 34px;
    }

    .historyAddressInfo {
      width: 582px;
      margin-right: auto;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
    }

    .deleteHistory {
      .size(32px, 32px);
      margin-left: 30px;
      box-sizing: border-box;
      background: url("../../assets/delete_add@2x.png") 0 0 no-repeat;
      background-size: 32px 32px;
    }
  }
}
</style>
